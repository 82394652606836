<template>
  <ul class="flex flex-wrap">
    <li 
      v-for="(tag, index) in formattedTags"
      :key="`${index}tag`"
      class="mr-2 mb-2 tag-item px-2 rounded-lg flex m-0"
    >
      <span> {{ tag }} </span>
      <closeIcon v-if="showClose" class="cursor-pointer" @close="removetag(tag)"/>
    </li>
  </ul>
</template>

<script>
  import closeIcon from '@/components/icons/close-icon'

  export default {
    data() {
      return {
        formattedTags: []
      }
    },
    props: {
      tags: {
        type: Array,
        default: () => []
      },
      showClose: {
        type: Boolean,
        default: true
      }
    },
    components: {
      closeIcon
    },
    methods: {
      removetag(tag){
        this.$emit('removetag', tag)
      }
    },
    mounted() {
      this.formattedTags = JSON.parse(this.tags)
    }
  }
</script>

<style lang="scss" scoped>
.tag-item{
  background-color: #FFEECE;
  border-radius: 8px;
  color: #E1970B;
}
</style>