<template>
<form @submit.prevent="createNewTag" class="flex">
  <div class="relative search-input w-full">
    <input 
      v-model="newTagName"
      type="text"
      placeholder="New Tag"
      class="font-mono text-xs p-2 mt-4 outline-none border-b border-grey-darker-100 w-full"
      :class="inputClasses"
      required
    >
  </div>
  <button
    class="border-grey-darker-100 border rounded-3xl px-2 py-1 text-sm font-mono font-medium mt-4 ml-4"
    type="submit"
  > 
    Add
  </button>
</form>
</template>

<script>
  export default {
    props:{
      inputClasses: {
        type: String,
        default: ''
      }
    },
    data(){
      return{
        newTagName: ''
      }
    },
    methods: {
      createNewTag(){
        this.$emit('createNewTag', this.newTagName)
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>