<template>
  <h2 
    class="flex flex-wrap justify-between antialiased border-b base-border-grey pb-4"
    :class="[ headClasses, alighY ]"
  >
    <span class="text-black font-sans font-sansDemi flex items-center"> 
      <slot name="icon"></slot>
      <span :class="[titleSize]"> {{ title }} </span>
    </span>
    <slot name="button"></slot>
    <div v-if="search" class="relative search-input">
      <input 
        v-model="searchTerm"
        type="text"
        @input="searchEvent"
        :placeholder="placeholder"
        class="font-mono text-xs p-2 w-52 outline-none"
      >
    </div>
    <slot name="sort"></slot>
    <slot name="labelsSort"></slot>
  </h2>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      search: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ''
      },
      headClasses: {
        type: String,
        default: ''
      },
      alighY: {
        type: String,
        default: 'items-center'
      },
      titleSize: {
        type: String,
        default: 'text-2xl'
      }
    },
    data(){
      return{
        searchTerm: ''
      }
    },
    methods: {
      searchEvent(){
        this.$emit('search', this.searchTerm)
      }
    },
  }
</script>

<style lang="scss" scoped>
.search-input{
  &:before{
    content: '';
    position: absolute;
    background-image: url('../assets/icons/search.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 0.8rem;
    height: 0.8rem;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>