<template>
  <ul class="overflow-auto">
    <li 
      v-for="(item, index) in items"
      :key="item.id"
      class="cursor-pointer"
      :class="{
        'border-dashed': index != hideBorderIndex,
        'border-b': index != hideBorderIndex,
      }"
      @click="selectLine(item.component)"
    >
      <div class="flex items-center justify-between py-3">
        <span 
          class="text-base base-font-gray-200"
          :class="{
            'font-sansDemi': activeLine == item.component,
            'active-item': activeLine == item.component,
          }"  
        > 
          {{ item.title }}
        </span>
      </div>
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        default: () => []
      },
      hideBorderIndex: {
        type: Number,
        default: -1
      },
      activeLine: {
        type: String
      },
    },
    methods: {
      selectLine(component){
        this.$emit('selectLine', component)
      }
    },
  }
</script>

<style lang="scss" scoped>
.active-item{
  color: #202020!important;
}
</style>