<template>
  <ul 
    class="overflow-auto max-h-60"
    :classes="classes"
  >
    <!-- <ul v-if="loading">
    <li v-for="(chan) in [0,1,2,3,4,5]" :key="chan" class="border-dashed border-b note-line" >
      <span class="loading-bar rounded animate-pulse"></span>
    </li>
  </ul> -->
    <li 
      v-for="(channel, index) in channels"
      :key="`${channel[idKey]}${index}`"
      class="border-dashed border-b note-line"
    >
      <div class="flex items-center justify-between"
        :class="itemInnerClasses"
      >
        <!-- <router-link :to="`/bucket/${bucketID}/channel/${channel.id}`"> -->
          <span class="text-base base-font-gray-200"> {{ channel.title }} </span>
        <!-- </router-link> -->
        <span 
          @click="removeChannel(channel.type.split('#')[1])"
          class="cursor-pointer invisible close-channel-icon"
        > 
          <img :src="require('../../assets/icons/close-icon.svg')" alt=""> 
        </span>
      </div>
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      channels: {
        type: Array,
        default: () => []
      },
      classes: {
        type: Array,
        default: () => []
      },
      idKey:{
        type: String,
        default: 'channel_id'
      },
      titleKey:{
        type: String,
        default: 'channel_name'
      },
      itemInnerClasses:{
        type: String,
        default: 'py-3'
      },
      bucketID:{
        type: String,
        default: ''
      },
      loading: {
        type: Boolean
      }
    },
    methods: {
      removeChannel(id){
        this.$emit('removeChannel', id)
      }
    },
  }
</script>

<style lang="scss" scoped>
.loading-bar {
  min-width: 10rem;
  margin: .65rem 0;
  background: #EFEFEF;
  height: 1.4rem;
  display: inline-flex;
}

.channels-list{
  height: calc(15rem + 5px);
}
.note-line{
  &:hover{
    .close-channel-icon{
      visibility: visible;
    }
  }
}
</style>