<template>
  <div v-if="bucket" class="relative">
    <div class="white-line absolute left-0 w-full h-px"></div>

    <div class="top-title pt-20 rounded absolute z-20 -ml-8 mt-4 pl-8 overflow-hidden" style="width: calc(100vw - 4rem)">
      <BucketHeader :channels="channels" />
      <div class="red-plus absolute -bottom-2 -left-2 w-4 h-4 z-20"></div>
      <div class="mx-auto">
        <div class="mx-8 px-4 border-b border-grey-darker-300 pb-5" style="align-items: flex-start;">
          <p class="font-mono text-lg base-font-gray-200 leading-none" :class="{'editable-bucket': isBucketAdmin}" @click="isBucketAdmin ? openChangeMetadata() : null"> 
            {{ `${bucket.altType[0].toUpperCase()}${bucket.altType.slice(1)}` }} Bucket
          </p>

          <h1 class="font-bold text-black head-title text-2xl leading-none pt-3 whitespace-nowrap" :class="{editable: isBucketAdmin}" @click="isBucketAdmin ? openChangeMetadata() : null"> 
            {{bucket.name}}
          </h1>  

        </div>
      </div>
    </div>
    <div class="mx-auto pt-10 relative">
      <div class="mx-8 mt-48">
        <p class="font-mono text-sm font-grey-darker-400 leading-none uppercase mb-2 px-4"> Description </p>
        <div class="flex flex-wrap">
          <div class="flex flex-wrap w-9/12">
            <div class="w-full xl:w-8/12 px-4 mt-4">

              <p class="base-font-gray-200 text-2xl"> 
                <editor-content class="text-2xl editable-border" v-if="isBucketAdmin" :editor="editor.description"/>
                  {{ !isBucketAdmin ? bucket.description : "" }} 
              </p>
                <ButtonWithLoader 
                  v-if="editor.descriptionChange"
                  :btnText="`Submit`"
                  bgClass="purple-bg"
                  textClass="purple-light font-monoDemi text-sm"
                  heigthClass="h-8"
                  class="my-3"
                  @click.native="submitInput(editor.description.getHTML(), 'description')"
                />

              <Title title="Videos (last 90 days)"/>
              <div class="pt-12 relative">
                <div 
                  v-if="isLoadingNewVideos" 
                  class="w-full text-center absolute left-0 top-0"
                >
                  <Loader 
                    :animation="true"
                    classes="w-8 h-8 mt-2"
                  />
                </div>
                <DynamicScroller
                  :items="newest_videos"
                  :min-item-size="54"
                  class="scroller videos"
                  key-field="videoId"
                  @scroll.native="checkLastItem"
                >
                  <template v-slot="{ item, index, active }">
                    <DynamicScrollerItem
                      :item="item"
                      :active="active"
                      :data-index="index"
                    >
                    <div class="border-dashed overflow-hidden border-b bucket-line flex items-center justify-between">
                      <span class="text-base base-font-gray-200 py-3 pr-4 w-3/4 relative">
                        {{ item.title == "" ? item.videoId : item.title }} ({{item.channelName}})
                        <span class="absolute w-12 h-full right-0 top-0 gradient"></span>
                      </span>
                      <span class="font-mono text-sm w-1/4 py-3 pr-3 time text-right">{{ formatDate(item.publishedAt*1000) }} ago</span>
                    </div>
                    </DynamicScrollerItem>
                  </template>
                </DynamicScroller>
              </div>
              <!-- <NewestVideosLines
                :items="newest_videos"
                class="mt-12"
              >
                <template #title>
                  <Title title="Videos (last month)"/>
                </template>
              </NewestVideosLines> -->
              <!-- <Title 
                title="Notes" 
                :search="true" 
                placeholder="Type here your searchterm"
                @search="filterNotes"
                class="mt-10"
              /> -->
              <ul class="flex flex-wrap w-full mt-2">
                <!-- <li 
                  v-for="(note, index) in notes"
                  :key="`${note.bucket_id}${index}`"
                  class="w-1/2 mt-8 flex flex-wrap"
                >
                  <span 
                    class="w-full block h-full border rounded bucket-line px-2 py-6 block"
                    :class="{
                      'mr-8': (index + 1) % 2 != 0
                    }"
                  >
                    <span class="text-xl text-black w-full block mb-2 leading-none"> {{ note.user_id }} </span>
                    <span class="w-full block text-base base-font-gray-200 mb-3"> 
                      {{ $moment(note.timestamp).local().format('DD/MM/YYYY MM:SS') }} CEST
                    </span>
                    <span class="font-sansDemi base-font-gray-200 text-base w-full block"> {{ note.message }} </span>
                  </span>
                </li> -->
              </ul>
            </div>
            <div class="w-full xl:w-4/12 px-4 mt-10 xl:mt-0">
              <Title :title="`Channels ${channels.length}/30`" titleSize="text-xl"/>
              <ChannelsLines 
                v-if="channels.length !== 0"
                :loading="true"
                :channels="channels" 
                :classes="[ 'channels-list' ]" 
                @removeChannel="removeChannel"
                :bucketID="bucket.channelId"
              />

              <p 
                class="flex items-center text-base text-black mt-5 mb-1 cursor-pointer"
                @click="showModal"
                v-if="isBucketAdmin"
              >
                <img :src="require('@/assets/images/chevron-double-right.svg')" class="mr-2">
                Add Channel Manually
              </p>
              <router-link 
                to="/discovery"
                class="flex items-center text-base text-black"
                v-if="isBucketAdmin"
              >
                <img :src="require('@/assets/images/chevron-double-right.svg')" class="mr-2">
                Start Discovery
              </router-link>

              <div v-if="channels.length === 0 && isBucketAdmin">
                Start adding your first channels
              </div>

              <div v-if="isBucketAdmin || bucket.methodology.length >= 1">
                <Title title="Methodology" class="mt-12" titleSize="text-xl"/>
                <p class="base-font-gray-200 text-base mt-4 font-sansDemi leading-6"> 
                  <editor-content v-if="isBucketAdmin" :editor="editor.methodology" />
                  {{ !isBucketAdmin ? bucket.methodology : "" }} 
                </p>
                <ButtonWithLoader 
                  v-if="editor.methodologyChange"
                  :btnText="`Submit`"
                  bgClass="purple-bg"
                  textClass="purple-light font-monoDemi text-sm"
                  heigthClass="h-8"
                  class="my-3"
                  @click.native="submitInput(editor.methodology.getHTML(), 'methodology')"
                />
              </div>
            </div>
          </div>
          <div class="w-3/12 px-4">
            <div 
              v-if="bucket.is_admin"
              class="px-4 bucket-admin-wrap rounded pt-8 bg-white -mt-32 mb-8"
            >
              <Title title="Bucket Admin" titleSize="text-xl"/>
              <CustomLines 
                :items="customLines" 
                :hideBorderIndex="customLines.length - 1"
                @selectLine="selectCustomLine"
              />
            </div>
            <div class="px-4">
              <p class="uppercase font-mono text-sm font-grey-darker-400 mb-4"> MAINTAINED BY </p>
              <p class="text-base base-font-gray-200 mb-2 leading-none"> Team or user name </p>
              <p class="text-sm base-font-gray-200 mb-1"> Team or user description. </p>

              <div 
                class="mt-8"
                v-if="isBucketAdmin">
                <p class="uppercase font-mono text-sm font-grey-darker-400 mb-4"> ADMIN </p>
                <p 
                  class="flex items-center text-base text-black mt-2 mb-1 cursor-pointer"
                  @click="deleteBucket"
                >
                  <img :src="require('@/assets/images/chevron-double-right.svg')" class="mr-2">
                  Delete Bucket
                </p>
              </div>

              <p class="uppercase font-mono text-sm font-grey-darker-400 mt-8 mb-4"> Tags </p>

              <Tags :tags="bucket.tags" />
              <AddTagForm @createNewTag="createNewTag"/>

              <p class="uppercase font-mono text-sm font-grey-darker-400 mt-8 mb-2"> LATEST ACTIVITIES </p>

              <ul class="flex flex-wrap">
                <!-- <li 
                  v-for="(activity, index) in last_activities"
                  :key="`${activity.id}${index}`"
                  class="mb-2 pb-4 pt-4 border-dashed border-b w-full"
                >
                  <div class="flex justify-between items-end mb-2">
                    <span class="block text-base base-font-gray-200 font-sansDemi leading-none"> {{ activity.activity }} </span>
                    <span class="block text-sm base-font-gray-200 leading-none"> {{ formatDate(activity.date) }} ago</span>
                  </div>
                  <span class="base-font-gray-light-100 base-bg-100 py-1 px-2 rounded-lg"> {{ activity.id }} </span>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>


    <modal 
      name="change-details" 
      :clickToClose="true"
      :classes="[ 'z-50', 'bg-white' ]"
      :width="'50%'"
      :height="'40%'"
    >

    <div>
      <h1>Coming soon</h1><br/>
      <span>Change bucket type</span>
      <span>Change title</span>
      <span>Change description</span>
      <span>Change methodology</span>

    </div>
      
    </modal>

    <modal 
      name="add-channel-modal" 
      :clickToClose="false"
      :classes="[ 'z-50', 'bg-white' ]"
      :width="'50%'"
      :height="'40%'"
    >
      <closeIcon
        class="cursor-pointer bg-white rounded-full absolute top-0 right-0 m-3"
        @close="hideModal"
      />
      <div class="h-full flex flex-wrap items-center justify-center">
        <div class="w-2/3">
          <p class="text-xl text-black w-full mb-8">Add a Channel ID or URL</p>
          <form 
            @submit.prevent="matchYoutubeChannel"
            class="flex flex-wrap items-center"
          >
            <input 
              v-model="channelUrlOrId"
              type="text"
              id="channelUrlOrId"
              placeholder="YouTube channel URL or ID"
              autocomplete="off"
              class="w-full base-bg-darker-700 rounded px-4 h-8 text-sm text-black outline-none placeholder-black"
            >
            <div class="w-full flex justify-end">
              <ButtonWithLoader 
                :disabled="loading"
                btnText="Add this Channel"
                borderClass="border border-white"
                textClass="text-black font-monoDemi text-sm"
                bgClass="base-bg-darker-160"
                type="submit"
                heigthClass="h-8"
                class="mt-6"
              />
            </div>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ChannelsLines from '@/components/BucketsBlocks/ChannelsLines'
import CustomLines from '@/components/BucketsBlocks/CustomLines'
// import NewestVideosLines from '@/components/BucketsBlocks/NewestVideosLines'
import Title from '@/components/Title'
import Tags from '@/components/Tags'
import AddTagForm from '@/components/AddTagForm'
import ButtonWithLoader from '@/components/ButtonWithLoader'
import closeIcon from '@/components/icons/close-icon'
import Loader from '@/components/Loader'

import { warningToastConfig } from '@/helpers/constants'
import ToastMessages from '@/data/toast_messages.json'
import { formatDate, formatChannelURLToID } from '@/helpers/formats'
import { validateChannelURL, validateChannelID } from '@/helpers/validaions'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Heading from '@tiptap/extension-heading'
import BucketHeader from '../../components/Bucket/BucketHeader.vue'

export default {
  name: "Bucket",
  components: {
    ChannelsLines,
    CustomLines,
    // NewestVideosLines,
    Title,
    Tags,
    AddTagForm,
    ButtonWithLoader,
    EditorContent,
    closeIcon,
    BucketHeader,
    Loader
},
  data() {
    return {
      channelUrlOrId: '',
      loading: false,
      isBucketAdmin: false,
      bucket: null,
      isLoadingNewVideos: false,
      newest_videos: [],
      // notes: [],
      // baseNotes: [],
      channels: [],
      last_activities: [],
      customLines: [ 
        {
          title: 'Add New Channel',
          id: 1
        }, 
        {
          title: 'Remove Channel',
          id: 2
        }, 
        {
          title: 'Delete Bucket',
          id: 3
        }, 
        {
          title: 'Workflow',
          id: 4
        }
      ],
      editor: {
        description: null,
        descriptionChange: false,
        methodology: null,
        methodologyChange: false,
        bucketChange: false
      },
      videosTimeline: {
        start: this.$moment().subtract(7, 'days').unix(),
        end: this.$moment().unix()
      }
    }
  },
  methods: {
    formatDate: formatDate,
    openChangeMetadata(){
      this.$router.push(`/bucket-admin/${this.bucket.bucketId}`)
      // this.$modal.show('change-details')
    },
    showModal(){
      this.$modal.show('add-channel-modal');
    },
    hideModal(){
      this.$modal.hide('add-channel-modal');
    },
    async matchYoutubeChannel(){
      if(this.channelUrlOrId.length){
        const isUrl = validateChannelURL(this.channelUrlOrId)
        const isID = validateChannelID(this.channelUrlOrId)
        let channelNameOrID = ''

        console.log(isUrl, isID, 'isID')
        if(isUrl){
          channelNameOrID = formatChannelURLToID(this.channelUrlOrId)
        }else if(isID){
          channelNameOrID = this.channelUrlOrId
        }else {
          this.$toasted.show(ToastMessages.buckets.invalid_url, warningToastConfig)
          return
        }
        // this.loading = true     
        try {
          const { data } = await this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/add_channel`, {
            channelId: channelNameOrID,
            bucketId: this.bucket.bucketId,
            fullScrape: true
          })
          this.channelUrlOrId = ''
          this.hideModal()
          this.$toasted.show("Channel Added", warningToastConfig)
          console.log(data, 'data')

          this.channels.push({
            "title": channelNameOrID,
            "id": channelNameOrID
          })
        } catch (error) {
          this.hideModal()
          this.$toasted.show(error, warningToastConfig)
        } finally{
          // this.loading = false
        }
      }else{
        this.$toasted.show(ToastMessages.buckets.invalid_url, warningToastConfig)
      }
    },
    // async addChannel(){

    // },
    // filterNotes(event){
    //   if(event.length){
    //     this.notes = this.baseNotes.filter(elem => {
    //       return elem.user_id.toLowerCase().indexOf(event.toLowerCase()) != -1 
    //       || elem.message.toLowerCase().indexOf(event.toLowerCase()) != -1
    //     })  
    //   }else{
    //     this.notes = JSON.parse(JSON.stringify(this.baseNotes))
    //   }
    // },
    createNewTag(newTagName){
      if(this.bucket.tags.indexOf(newTagName) == -1) this.bucket.tags.push(newTagName)
      else this.$toasted.show(ToastMessages.buckets.exsists, warningToastConfig)
    },
    selectCustomLine(id){
      console.log(id)
    },
    submitInput(data, type) {
      console.log(data)
      console.log(type)
    },
    async removeChannel(channelId) {
      await this.$axios.post(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/delete_channel`, {
        bucketId: this.bucket.id.split("bucket#")[1].split("#type")[0],
        channelId: channelId
      })

      alert(`Channel has been deleted from your bucket`)
    },
    async deleteBucket() {
      // console.log(this.bucket.id.split("bucket#")[1].split("#type")[0])
      await this.$axios.post(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/delete_bucket`, {
        bucketId: this.bucket.id.split("bucket#")[1].split("#type")[0]
      })

      alert(`This bucket has been deleted`)
    },
    async getMetadata(bid) {
      const { data } = await this.$axios.get(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-solo-bucket/${bid}`)
      return data;
    },
    async getChannels(bid) {
      const { data } = await this.$axios.get(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get_channels_bucket/${bid}`);
      return data;
    },
    async getVideos(bid) {
      const { data } = await this.$axios.get(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-videos-bucket/${bid}/min/${this.videosTimeline.start}/max/${this.videosTimeline.end}`);
      return data
    },
    async checkLastItem(el){
      if ( el.path[0].offsetHeight + el.path[0].scrollTop >= el.path[0].scrollHeight ) {
        this.isLoadingNewVideos = true
        const videos = await this.loadMoreVideos()       
        if(!videos.length) await this.loadMoreVideos() 
        this.isLoadingNewVideos = false
      }
    },
    async loadMoreVideos(){
      this.videosTimeline.end = this.videosTimeline.start
      this.videosTimeline.start = this.$moment.unix(this.videosTimeline.start).subtract(7, 'days').unix()

      try {
        const videos = await this.getVideos(this.$route.params.id)
        this.newest_videos = this.newest_videos.concat(videos)
        return videos
      } catch (error) {
        console.log(error)
        return []
      }
    }
  },
  async beforeMount(){
    // let notes = await this.$axios.get('/dummy-data/buckets/private_notes.json')
    let last_activities = await this.$axios.get('/dummy-data/buckets/private_latest.json')
    // let newest_videos =  await this.$axios.get('/dummy-data/buckets/private_videos.json')

    // this.notes = JSON.parse(JSON.stringify(notes.data.messages))
    // this.baseNotes = JSON.parse(JSON.stringify(notes.data.messages))
    this.last_activities = JSON.parse(JSON.stringify(last_activities.data))
    // this.newest_videos =  newest_videos.data
  },
  async mounted() {
    const bid = this.$route.params.id;

    this.bucket = await this.getMetadata(bid);
    this.isBucketAdmin = this.bucket.adminAcess;
    this.channels = await this.getChannels(bid);
    if (this.channels === false) { this.channels = []}
    const videos = await this.getVideos(bid);
    this.newest_videos = videos;

    this.bucket.methodology = false; // why?

    this.editor.bucketName = new Editor({
      content: `<h1 class="title-heading">${this.bucket.name}</h1>`,
      extensions: [
        StarterKit, 
      ],
      editorProps: {
       handleDOMEvents: {
        keypress: () => {
          this.editor.bucketChange = true;
        },
      },
      },
    })


    this.editor.methodology = new Editor({
      content: this.bucket.methodology,
      extensions: [
        StarterKit, 
        Placeholder.configure({
          placeholder: "Write a methodology"
        })
      ],
      editorProps: {
       handleDOMEvents: {
        keypress: () => {
          this.editor.methodologyChange = true;
        },
      },
      },
    })

    this.editor.description = new Editor({
      content: this.bucket.description.length === 0 ? 'Click here to write a Description' : this.bucket.description,
      extensions: [
        StarterKit, 
        Placeholder.configure({
          placeholder: "Write a description"
        }),
        Heading.configure({
          HTMLAttributes: {
            class: 'text-2xl',
          },
        })
      ],
      editorProps: {
       handleDOMEvents: {
        keypress: () => {
          this.editor.descriptionChange = true;
        },
      },
      },
    })

    this.editor.description.commands.setHeading({ level: 1 })


  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
.white-line{
  top: calc(2rem - 1px);
  background-color: #F6F6F6;
}
.top-title{
  background-color: #EFEFEF;
}
.bucket-admin-wrap{
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.11);
}

.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: rgb(164, 164, 164);
  font-style: italic;
  pointer-events: none;
  height: 0;
}

* {
  box-sizing: border-box;
}

.editable-bucket:hover {
  border: 2px dashed #AA95FF;
  border-radius: 4px;
  margin-left: -2px;
  margin-top: -2px !important;
  margin-bottom: -2px;
  padding-bottom: -2px;
  display: table;
}

.editable:hover {
  border: 2px dashed #AA95FF;
  border-radius: 4px;
  margin-left: -2px;
  margin-top: -2px;
  margin-bottom: -2px;
  padding-bottom: -2px;
  display: inline-flex
}

.editable-border:hover {
  border: 2px dashed #AA95FF;
  border-radius: 4px;
  margin-left: -2px;
  margin-top: -2px;
  margin-bottom: -2px;
}

.videos {
  max-height: 300px;
  overflow-y: auto;
}
</style>